.home {
  display: block;

}

.container {
  display : block
}

.bigtitle {
  transform: scale(1.5,1);
}

 h2.translated {
   position: absolute;
   top: 20%;
   left: 45%;
   text-transform: capitalize;
   color: black;
   font-size: 50px;
 }
 
 h2.translated::after {
   content: attr(data-text);
   position: absolute;
   top: 0;
   left: 0;
/* Change the position of transformed element */
   transform-origin: bottom;
/*  Rotates around x-axis */
   /* transform: rotateX(180deg) rotateY(180deg); */
   line-height: 2.6em;
/* linear-gradient defined by up,down,left ,right ,diagonal */
   background-image: linear-gradient(0deg, #ffffff 0, transparent 95%);
   -webkit-background-clip: text;
   color: black;         
   opacity: 0.7;
 }



 h2.inverted {
  position: absolute;
  top: 30%;
  left: 45%;
  text-transform: capitalize;
  color: black;
  font-size: 50px;
}

h2.inverted::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
/* Change the position of transformed element */
  transform-origin: bottom;
/*  Rotates around x-axis */
  transform: rotateX(180deg) rotateY(180deg);
  line-height: 0.85em;
/* linear-gradient defined by up,down,left ,right ,diagonal */
  background-image: linear-gradient(0deg, #ffffff 0, transparent 95%);
  -webkit-background-clip: text;
  color: black;         
  opacity: 0.7;
}
